/* // src/index.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.container {
  width: 100%;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}